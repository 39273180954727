"use client";

import { SnackbarProvider } from "notistack";

import { type WithChildren } from "@/lib/types";

import { Notification } from "./components/Notification";

export const SnackbarClientProvider = ({ children }: WithChildren) => (
  <SnackbarProvider
    preventDuplicate
    anchorOrigin={{ horizontal: "center", vertical: "top" }}
    Components={{
      warning: Notification,
      info: Notification,
      success: Notification,
      error: Notification,
    }}
    maxSnack={4}
  >
    {children}
  </SnackbarProvider>
);

"use client";

import { type Market, type WithMarket } from "@projectluna/lib/market/types";
import { createSafeContext } from "@projectluna/lib/react/context";
import { type WithChildren } from "@projectluna/lib/types";

const { useContext, Provider } = createSafeContext<Market>();

export const useGetMarket = () => useContext();

export const MarketProvider = ({
  children,
  market,
}: WithMarket & WithChildren) => (
  <Provider value={market}>{children}</Provider>
);

import { sendGTMEvent as nextSendGTMEvent } from "@next/third-parties/google";

import { CLIENT_CONFIG } from "@/config/client";

export const isGAEnabled = () => !!CLIENT_CONFIG.GA_MEASUREMENT_ID;

export const isGTMEnabled = () => !!CLIENT_CONFIG.GTM_ID;

type GAEvent =
  | "add_payment_info"
  | "add_shipping_info"
  | "add_to_cart"
  | "begin_checkout"
  | "view_item_list"
  | "purchase"
  | "remove_from_cart"
  | "view_cart"
  | "update"
  | "default"
  | "view_item"
  | "search";

type GAAction = "event" | "consent";

type GTMEvent = "conversion";

/**
 * TODO: IDK why but using nexts `sendGTMEvent` does not show in analytics reports.
 * Probably this requires proper GTM setup.
 */
export const sendGAEvent = ({
  action,
  data,
  event,
}: {
  action: GAAction;
  data: object;
  event: GAEvent;
}) => {
  if (isGAEnabled() && window.gtag) {
    window.gtag(action, event, data);
  }
};

export const sendGTMEvent = (
  data: Record<string, string | number> & { event: GTMEvent }
) => {
  if (isGTMEnabled()) {
    nextSendGTMEvent(data);
  }
};

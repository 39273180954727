"use client";

import { useEffect } from "react";

import { CLIENT_CONFIG } from "@/config/client";

export const Version = () => {
  useEffect(() => {
    window.release = CLIENT_CONFIG.RELEASE;
  }, []);

  return null;
};

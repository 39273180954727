"use client";

import { type IntlConfig, NextIntlClientProvider } from "next-intl";

import { intlGetMessageFallback, intlOnError } from "@/lib/intl/utils";
import { type WithChildren } from "@/lib/types";

type IntlProvider = WithChildren &
  Pick<IntlConfig, "timeZone" | "messages" | "locale">;

export const IntlProvider = ({
  timeZone,
  children,
  messages,
  locale,
}: IntlProvider) => (
  <NextIntlClientProvider
    getMessageFallback={intlGetMessageFallback}
    locale={locale}
    messages={messages}
    timeZone={timeZone}
    onError={intlOnError}
  >
    {children}
  </NextIntlClientProvider>
);

"use client";

import { Dialog, Transition } from "@headlessui/react";
import Cookies from "js-cookie";
import { useTranslations } from "next-intl";
import { Fragment, useEffect, useState } from "react";

import { Button } from "@/components/Button";
import { Link } from "@/components/Link";
import { CLIENT_CONFIG } from "@/config/client";
import { useLocalizedPaths } from "@/lib/paths/client";
import { sendGAEvent } from "@/lib/tracking/google/client";

export const CookieConsent = ({ isAccepted }: { isAccepted: boolean }) => {
  const t = useTranslations();
  const paths = useLocalizedPaths();

  const [isOpen, setIsOpen] = useState(!isAccepted);
  const isConsentAccepted =
    isAccepted ||
    Cookies.get(CLIENT_CONFIG.COOKIE_KEY.COOKIE_CONSENT) === "true";

  const handleAccept = () => {
    Cookies.set(CLIENT_CONFIG.COOKIE_KEY.COOKIE_CONSENT, "true", {
      expires: 150,
    });

    sendGAEvent({
      action: "consent",
      event: "update",
      data: {
        ad_user_data: "granted",
        ad_personalization: "granted",
        ad_storage: "granted",
        analytics_storage: "granted",
      },
    });

    setIsOpen(false);
  };

  useEffect(() => {
    if (!isConsentAccepted) {
      sendGAEvent({
        action: "consent",
        event: "default",
        data: {
          ad_storage: "denied",
          ad_user_data: "denied",
          ad_personalization: "denied",
          analytics_storage: "denied",
        },
      });
    } else {
      sendGAEvent({
        action: "consent",
        event: "update",
        data: {
          ad_user_data: "granted",
          ad_personalization: "granted",
          ad_storage: "granted",
          analytics_storage: "granted",
        },
      });
    }
  }, [isConsentAccepted]);

  if (isConsentAccepted) {
    return null;
  }

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="relative z-[9999] h-full w-full"
        onClose={() => null}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/40" />
        </Transition.Child>

        <div className="fixed inset-0 h-full w-full overflow-y-auto">
          <div className="flex h-[inherit] w-[inherit] items-end">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel as="div" className="w-full bg-white p-4">
                <div className="container flex items-center gap-4 max-md:flex-col">
                  <p className="grow">
                    {t.rich("site.cookieConsent", {
                      termsLink: chunks => (
                        <Link
                          className="text-gray-600 underline"
                          href={paths.terms.asPath()}
                          target="_blank"
                        >
                          {chunks}
                        </Link>
                      ),
                      privacyLink: chunks => (
                        <Link
                          className="text-gray-600 underline"
                          href={paths.privacyPolicy.asPath()}
                          target="_blank"
                        >
                          {chunks}
                        </Link>
                      ),
                    })}
                  </p>

                  <Button className="max-md:w-full" onClick={handleAccept}>
                    {t("common.accept")}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

import { type ComponentProps, forwardRef } from "react";

import { type WithChildren } from "@/lib/types";

import { cn } from "@/styles/lib";

type IconProps = WithChildren &
  ComponentProps<"span"> & { isProcessing?: boolean };

export const Icon = forwardRef<HTMLSpanElement, IconProps>(
  ({ className, isProcessing, ...props }, ref) => (
    <span
      className={cn(
        "inline-flex rounded-full p-2 transition-all",
        "cursor-pointer text-gray-500 hover:bg-gray-100 hover:text-gold [&_svg]:h-[24px]",
        { "pointer-events-none text-gray-300": isProcessing },
        className
      )}
      ref={ref}
      {...props}
    />
  )
);

Icon.displayName = "Icon";

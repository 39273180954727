import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

import { config } from "./config";

const baseFont = Number(
  config.theme.fontSize.base[0].replace(/[^\d\.]*/g, "")
);

export const cn = (...input: ClassValue[]) => twMerge(clsx(input));

export const px2rem = (...size: number[]): string =>
  size.map(s => `${s / baseFont}rem`).join(" ");

type ScreenSize = keyof typeof config.theme.screens;
export const screenSizes = Object.entries(config.theme.screens).reduce(
  (acc, [breakpoint, size]) => {
    acc[breakpoint as ScreenSize] = Number(size.match(/\d+/g)?.[0]);
    return acc;
  },
  {} as Record<ScreenSize, number>
);

export type ScreenSizesDefinition = { size?: number; width: string }[];

export const generateSizes = (
  sizes: ScreenSizesDefinition,
  defaultSize?: string
) =>
  [
    ...sizes.map(({ size, width }) =>
      size ? `(max-width: ${size}px) ${width}` : width
    ),
    defaultSize,
  ]
    .filter(Boolean)
    .join(", ");

export const carouselCardSizes = generateSizes(
  [
    { size: screenSizes.sm, width: "100vw" },
    { size: screenSizes.md, width: "50vw" },
    { size: screenSizes.lg, width: "33vw" },
  ],
  "25vw"
);

export const gridCardSizes = generateSizes(
  [
    { size: screenSizes.md, width: "50vw" },
    { size: screenSizes.lg, width: "33vw" },
  ],
  "25vw"
);

export const productCarouselSizes = generateSizes(
  [
    { size: screenSizes.lg, width: "100vw" },
    { size: screenSizes["2xl"], width: "50vw" },
  ],
  "850px"
);

export const lineSizes = generateSizes([{ width: "100px" }]);

export const aboutSizes = generateSizes(
  [{ size: screenSizes.lg, width: "50vw" }],
  "100vw"
);

import typography from "@tailwindcss/typography";
import type { Config } from "tailwindcss";
import colors from "tailwindcss/colors";
import plugin from "tailwindcss/plugin";

const config: Config = {
  content: ["./src/**/*.{js,ts,jsx,tsx,mdx}"],
  future: {
    hoverOnlyWhenSupported: true,
  },
  theme: {
    container: {
      center: true,
    },
    extend: {
      backdropBlur: {
        xs: "2px",
      },
      screens: {
        xs: "360px",
      },
      container: {
        padding: {
          sm: "1.5rem",
          md: "2rem",
        },
      },
      scale: {
        82: "0.82",
      },
      fontFamily: {
        secondary: "var(--font-secondary)",
        primary: "var(--font-primary)",
      },
      colors: {
        floralWhite: "#fbfaf8",
        gold: {
          "50": "#f6f5f0",
          "100": "#e9e6d8",
          "200": "#d5ccb3",
          "300": "#bdae87",
          "400": "#b29d74",
          "500": "#9b8157",
          "600": "#856949",
          "700": "#6b523d",
          "800": "#5b4638",
          "900": "#503d33",
          "950": "#2d211b",
          DEFAULT: "#b29d74",
        },
        gray: {
          500: "#9e9e9e",
          600: "#616161",
        },
        success: colors.green["700"],
        danger: colors.red["600"],
        warning: colors.orange["500"],
      },
      transitionProperty: {
        background: "background",
      },
      borderRadius: {
        DEFAULT: "4px",
      },
      keyframes: {
        scrollTextStart: {
          "0%": {
            transform: "translateX(100%)",
          },

          "80%": { transform: "translateX(-200%)" },
          "100%": { transform: "translateX(-200%)" },
        },
        ticker: {
          "100%": {
            transform: "translateX(calc(-50% - 50px))",
          },
        },
        move: {
          "0%": { transform: "translate(0, 0)" },
          "100%": { transform: "translate(-100%, 0)" },
        },
        banner: {
          "0%": {
            transform: "translate3d(0, 0, 0)",
          },
          "100%": {
            transform: "translate3d(-50%, 0, 0)",
          },
        },
      },
      animation: {
        banner: "banner 2s linear infinite",
        scrollTextStart: "scrollTextStart 10s infinite linear",
        ticker: "ticker 10s infinite linear",
        move: "move 5s linear infinite",
      },
    },
  },
  plugins: [
    typography,
    plugin(({ addVariant }) => {
      addVariant("scrollbar", ["&::-webkit-scrollbar", "&::scrollbar"]);
      addVariant("scrollbar-thumb", [
        "&::-webkit-scrollbar-thumb",
        "&::scrollbar-thumb",
      ]);
      addVariant("scrollbar-vertical", [
        "&::-webkit-scrollbar:vertical",
        "&::scrollbar:vertical",
      ]);
      addVariant("scrollbar-horizontal", [
        "&::-webkit-scrollbar:horizontal",
        "&::scrollbar:horizontal",
      ]);
      addVariant("slider-thumb", [
        "&::-webkit-slider-thumb",
        "&::-moz-range-thumb",
        "&::slider-thumb",
      ]);
      addVariant("slider-thumb-track", [
        "&::-webkit-slider-runnable-track",
        "&::-moz-range-track",
        "&::slider-runnable-track",
      ]);
    }),
    plugin(function ({ matchUtilities, theme }) {
      matchUtilities(
        {
          "translate-z": value => ({
            "--tw-translate-z": value,
            transform: ` translate3d(var(--tw-translate-x), var(--tw-translate-y), var(--tw-translate-z)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))`,
          }), // this is actual CSS
        },
        { values: theme("translate"), supportsNegativeValues: true }
      );
    }),
  ],
};

export default config;

"use client";

import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  FireIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import {
  type InternalSnack,
  SnackbarContent,
  useSnackbar,
  type VariantType,
} from "notistack";
import { forwardRef, useState } from "react";

import { Icon } from "@/components/Icon";
import { useInterval } from "@/hooks/useInterval";

import { cn } from "@/styles/lib";

const NOTIFICATION_STYLES: Record<
  VariantType,
  {
    className: {
      bar: string;
      barBackground: string;
      icon: string;
    };
    icon: typeof ExclamationTriangleIcon;
  }
> = {
  warning: {
    icon: ExclamationTriangleIcon,
    className: {
      icon: "text-yellow-500",
      bar: "bg-yellow-500",
      barBackground: "bg-yellow-500/25",
    },
  },
  info: {
    icon: InformationCircleIcon,
    className: {
      icon: "text-blue-400",
      bar: "bg-blue-400",
      barBackground: "bg-blue-400/25",
    },
  },
  error: {
    icon: FireIcon,
    className: {
      icon: "text-red-600",
      bar: "bg-red-600",
      barBackground: "bg-red-600/25",
    },
  },
  success: {
    icon: CheckCircleIcon,
    className: {
      icon: "text-green-600",
      bar: "bg-green-600",
      barBackground: "bg-green-600/25",
    },
  },
};

export const Notification = forwardRef<HTMLDivElement, InternalSnack>(
  ({ id, message, variant, autoHideDuration }, ref) => {
    const { closeSnackbar } = useSnackbar();
    const [width, setWidth] = useState(100);
    const delay = autoHideDuration
      ? width > 0
        ? autoHideDuration / 100
        : null
      : null;

    const className = NOTIFICATION_STYLES[variant!].className;
    const VariantIcon = NOTIFICATION_STYLES[variant!].icon;

    useInterval(() => {
      setWidth(width => width - 1);
    }, delay);

    return (
      <SnackbarContent
        className="w-full sm:w-[75vw] md:w-[50vw] lg:w-[500px]"
        key={id}
        ref={ref}
      >
        <div
          className="border-opacity-755 relative w-full overflow-hidden rounded-md border bg-white shadow-md"
          role="alert"
        >
          <div className="flex h-full items-center gap-4 px-4 py-6">
            <div>
              <VariantIcon className={className.icon} height={35} />
            </div>

            <div className="h-full w-[1px] bg-gray-300" />

            <p className="break-word basis-full text-sm font-semibold text-gray-700/75">
              {message}
            </p>

            <div
              className="flex h-full items-start"
              onClick={() => closeSnackbar(id)}
            >
              <Icon>
                <XMarkIcon />
              </Icon>
            </div>
          </div>

          {autoHideDuration ? (
            <>
              <div
                className={cn(
                  "absolute inset-x-0 bottom-0 h-1.5 w-full",
                  className.barBackground
                )}
              />
              <div
                className={cn(
                  "absolute inset-x-0 bottom-0 h-1.5 transition-all",
                  className.bar
                )}
                style={{
                  width: `${width}%`,
                }}
              />
            </>
          ) : (
            <div
              className={cn(
                "absolute inset-y-0 left-0 h-full w-1.5",
                className.bar
              )}
            />
          )}
        </div>
      </SnackbarContent>
    );
  }
);
Notification.displayName = "Notification";

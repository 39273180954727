import { z } from "zod";

export const appConfigSchema = (
  z.object({
    VERSION: z.string(),
    NAME: z.string(),
    RELEASE: z.string().optional(),
  }) as any as z.ZodObject<{
    NAME: z.ZodString;
    RELEASE: z.ZodString;
    VERSION: z.ZodString;
  }>
).refine(data => {
  if (data.VERSION && data.NAME) {
    data.RELEASE = `${data.NAME}@${data.VERSION}`;
  }
  return data;
});

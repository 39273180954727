import NextLink from "next/link";
import React, { type ComponentProps } from "react";

import { type WithChildren } from "@/lib/types";

import { cn } from "@/styles/lib";

export type LinkProps = ComponentProps<typeof NextLink> &
  WithChildren & { isDisabled?: boolean };

export const Link = ({ className, isDisabled, ...props }: LinkProps) => (
  <NextLink
    prefetch={false}
    {...props}
    className={cn(className, { "pointer-events-none": isDisabled })}
  />
);

import { type CSSProperties } from "react";

import { type WithHTMLProp } from "@/lib/types";

import { cn } from "@/styles/lib";

type SpinnerProps = WithHTMLProp<"className"> & {
  borderSize?: CSSProperties["borderWidth"];
  size?: CSSProperties["height"];
};

export const Spinner = ({
  className,
  size = 20,
  borderSize,
}: SpinnerProps) => (
  <div
    className={cn("relative block border-gold", className)}
    role="status"
    style={{
      height: size,
      width: size,
    }}
  >
    <div
      className={cn(
        "absolute animate-spin rounded-full border-2 border-solid border-inherit",
        "border-t-transparent"
      )}
      style={{
        height: size,
        width: size,
        borderWidth: borderSize,
      }}
    />
  </div>
);

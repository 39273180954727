import get from "lodash/get";
import { type IntlConfig, IntlErrorCode } from "next-intl";

import { captureError } from "@projectluna/lib/errors";

import defaultMessages from "@/messages/pl-PL.json";

export const intlOnError: IntlConfig["onError"] = error => {
  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    captureError(new Error("Missing translation message."), {
      extra: { error },
    });
  } else {
    captureError(error);
  }
};

export const intlGetMessageFallback: IntlConfig["getMessageFallback"] = ({
  key,
  namespace,
}) => {
  const path = [namespace, key].filter(Boolean).join(".");
  return get(defaultMessages, path) || path;
};

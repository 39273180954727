export const LOG_LEVELS = ["debug", "info", "warn", "error"] as const;

export type LogLevel = (typeof LOG_LEVELS)[number];

export type LeveledLogMethod = {
  (message: string, ...meta: any[]): void;
  (message: any): void;
  (infoObject: object): void;
};

export type LoggerFactoryConfig = {
  browserLevel: LogLevel;
  level: LogLevel;
  pretty?: boolean;
  redactKeys?: boolean;
};

export type Logger = Record<LogLevel, LeveledLogMethod>;

export type LoggerServiceFactory<
  Config extends LoggerFactoryConfig = LoggerFactoryConfig,
> = unknown extends Config ? () => Logger : (opts: Config) => Logger;

"use client";

import { AppProgressBar as ProgressBar } from "next-nprogress-bar";

export const AppRouterProgressBar = () => (
  <ProgressBar
    shallowRouting
    color="#b29d74"
    height="5px"
    options={{ showSpinner: false }}
  />
);

import React from "react";

export function createSafeContext<TValue>(
  errorMessage = "useContext must be inside a Provider with a value"
) {
  const context = React.createContext<TValue | undefined>(undefined);

  function useContext() {
    const value = React.useContext(context);

    if (value === undefined) {
      throw new Error(errorMessage);
    }

    return value;
  }

  return {
    useContext,
    Provider: context.Provider,
    Consumer: context.Consumer as React.Consumer<TValue>,
  } as const;
}

export function createCompoundContext<TValue>(name: string) {
  const { useContext, Provider } = createSafeContext<TValue>(
    `${name} compound components cannot be rendered outside ${name} component.`
  );
  return { CompoundProvider: Provider, useCompoundContext: useContext };
}

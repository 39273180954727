"use client";

import { getPaths } from "@projectluna/lib/paths/getPaths";

import { useGetMarket } from "../market/client";

export const useLocalizedPaths = () => {
  const market = useGetMarket();

  return getPaths(market.slug);
};
